<template>
  <div v-if="show" class="box">
    <el-form :model="shopForm" :rules="storeRules" ref="shopForm" style="width: 50%;">
          <el-form-item
            label="所属商家"
            :label-width="shopFormLabelWidth"
            prop="hierarchy_pid"
            ref="hierarchy_pid"
          >
            <el-input disabled v-model="shopForm.connection_merchant"></el-input>
            <!-- <el-select
              v-model="shopForm.hierarchy_pid"
              filterable
              placeholder="请选择所属商家"
              :disabled="false"
              @change="handleBusiness"
            >
              <template v-for="(item, index) in hierarchy_list">
                <el-option
                  :label="item.name"
                  :key="index"
                  :value="item.hierarchy_id"
                ></el-option>
              </template>
            </el-select> -->
          </el-form-item>
          <el-form-item
            label="门店名称"
            :label-width="shopFormLabelWidth"
            prop="name"
          >
            <el-input
              v-model="shopForm.name"
              autocomplete="off"
              placeholder="请输入门店名称"
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="门店简称"
            :label-width="shopFormLabelWidth"
            prop="short_name"
          >
            <el-input
              v-model="shopForm.short_name"
              autocomplete="off"
              placeholder="请输入门店简称"
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  prop="provinceId"
                  label="所属区域"
                  label-width="100px"
                >
                  <el-select
                    v-model="shopForm.provinceId"
                    filterable
                    placeholder="请选择省"
                    @change="handleProvie"
                    style="margin-right: 15px"
                  >
                    <template v-for="(item, index) in provinceList">
                      <el-option
                        :label="item.name"
                        :value="item.id"
                        :key="index"
                      ></el-option>
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="cityId" ref="cityId" label-width="15px">
                  <el-select
                    v-model="shopForm.cityId"
                    filterable
                    placeholder="请选择市"
                    @change="handleCity"
                  >
                    <template v-for="(item, index) in cityList">
                      <el-option
                        :label="item.name"
                        :value="item.id"
                        :key="index"
                      ></el-option>
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="areaId" ref="areaId" label-width="5px">
                  <el-select
                    v-model="shopForm.areaId"
                    filterable
                    placeholder="请选择区"
                    @change="handleArea"
                  >
                    <template v-for="(item, index) in areaList">
                      <el-option
                        :label="item.name"
                        :value="item.id"
                        :key="index"
                      ></el-option>
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item
            label="详细地址"
            :label-width="shopFormLabelWidth"
            prop="addr"
          >
            <el-input
              v-model="shopForm.addr"
              autocomplete="off"
              placeholder="请输入详细地址"
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="固定电话"
            :label-width="shopFormLabelWidth"
            prop="addr"
          >
            <el-input
              v-model="shopForm.tel"
              autocomplete="off"
              placeholder="请输入固定电话"
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item label="门店LOGO" :label-width="shopFormLabelWidth">
            <div style="display: flex; flex-direction: row">
              <el-upload
                class="avatar-uploader"
                action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
                :data="{
                  directory: 'AGENT_INFO',
                }"
                :headers="{
                  authorization: token,
                }"
                :show-file-list="false"
                :on-success="handleStoreLogoUrlSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="storeLogo_img"
                  :src="storeLogo_img"
                  class="avatarOther"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="营业执照" :label-width="shopFormLabelWidth">
            <div style="display: flex; flex-direction: row">
              <el-upload
                class="avatar-uploader"
                action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
                :data="{
                  directory: 'AGENT_INFO',
                }"
                :headers="{
                  authorization: token,
                }"
                :show-file-list="false"
                :on-success="handleStoreLicenseUrlSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="storeLicense_img"
                  :src="storeLicense_img"
                  class="avatarOther"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </el-form-item>
          <!-- <div v-if="dialogType === 'add'">
            <div class="title">
              <div class="span"></div>
              店长账号
            </div>
            <el-form-item
              label="店长姓名"
              :label-width="shopFormLabelWidth"
              prop="admin_name"
            >
              <el-input
                v-model="shopForm.admin_name"
                autocomplete="off"
                placeholder="请输入店长姓名"
                style="width: 300px"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="店长手机号"
              :label-width="shopFormLabelWidth"
              prop="admin_account"
            >
              <el-input
                v-model="shopForm.admin_account"
                autocomplete="off"
                placeholder="请输入店长手机号"
                style="width: 300px"
              ></el-input>
            </el-form-item>
          </div> -->
          <el-form-item :label-width="shopFormLabelWidth">
            <el-button type="primary"  @click="storeSubmit('shopForm')">保存</el-button>
          </el-form-item>
        </el-form>
  </div>
</template>

<script>
import storage from 'good-storage';
import Cookies from "js-cookie";
import { merchantDetail as shopDetails, shopUpdate } from "@/api/businessManage/business";
import { getRegionList } from "@/api/agentManage/index";

export default {
  data() {
    const userInfo = storage.get('userInfo');
    let validatorD = (rule, value, callback) => {
      if (!/^[1][3,4,5,7,8][0-9]{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    };
    let validateName = (rule, value, callback) => {
      let reg = /^[a-zA-Z\u2E80-\u9FFF]+$/; //Unicode编码中的汉字范围
      if (value && value.length > 0) {
        if (!reg.test(value)) {
          callback([new Error("名称输入不合法")]);
        } else {
          callback();
        }
      } else if (value.length == 0) {
        callback();
      } else {
        callback(new Error("名称输入不合法"));
      }
    };
    let validateNum = (rule, value, callback) => {
      if (!/^\d+$/.test(value)) {
        callback(new Error("请输入正整数"));
      } else {
        callback();
      }
    };
    return {
      show: false,
      provinceList: [],
      cityList: [],
      areaList: [],
      dialogType: 'edit',
      userInfo,
      shopFormLabelWidth: "100px",
      shopForm: {},
      storeLogo_img: '',
      storeLogo_url: '',
      storeLicense_img: '',
      storeLicense_url: '',
      region_id: '',
      storeRules: {
        name: [
          {
            required: true,
            message: "请填写门店名称",
            trigger: "blur",
          },
        ],
        short_name: [
          {
            required: true,
            message: "请填写门店简称",
            trigger: "blur",
          },
        ],
        addr: [
          {
            required: true,
            message: "请填写详细地址",
            trigger: "blur",
          },
        ],
        admin_name: [
          {
            required: true,
            message: "请输入店长姓名",
            trigger: "blur",
          },
          {
            validator: validateName,
            trigger: "blur",
          },
        ],
        admin_account: [
          {
            required: true,
            message: "请输入店长手机号",
            trigger: "blur",
          },
          {
            validator: validatorD,
            trigger: "blur",
          },
        ],
        provinceId: [
          {
            required: true,
            message: "请选择省份",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    if (this.userInfo.hierarchy_type_id == 40) {
      this.token = storage.session.get("token") || Cookies.get("token");
      this.getProvinceList();
      this.getShopDetail();
    }
  },
  methods: {
     /*
     *   保存门店信息
     */
    storeSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = { ...this.shopForm };
          delete form.provinceId;
          delete form.cityId;
          delete form.areaId;
          delete form.region;
          if (this.dialogType === "edit") {
            delete form.admin_name;
            delete form.admin_account;
          }
          form.logo = this.storeLogo_url;
          form.license_url = this.storeLicense_url;
          form.region_id = this.region_id;
          if (this.dialogType === "add") {
            shopAdd(form).then((res) => {
              if (res.code === 1) {
                // this.visiable = false;
                // this.handleCance();
                this.$message({
                  message: "添加门店成功！",
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.msg,
                  type: "error",
                });
              }
            });
          } else {
            delete form.phone_num;
            delete form.audit_admin_user;
            delete form.audit_admin_user_id;
            delete form.audit_remark;
            delete form.hierarchy_connection;
            delete form.legal_person_name;
            delete form.phone_num;
            delete form.store_scale;
            delete form.update_admin_user;
            delete form.update_admin_user_id;
            delete form.update_time;
            form.hierarchy_id = this.hierarchy_id;
            shopUpdate(form).then((res) => {
              if (res.code === 1) {
                // this.visiable = false;
                // this.handleCance();
                this.$message({
                  message: "更新成功！",
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.msg,
                  type: "error",
                });
              }
            });
          }
        }
      });
    },
    beforeAvatarUpload(file) {
      const isImg = file.type === "image/jpeg" || file.type === "image/png";
      if (!isImg) {
        this.$message.error("只能上传图片格式");
      }

      return isImg;
    },
    /*
     *  门店Logo图片上传
     */
    handleStoreLogoUrlSuccess(res, file) {
      this.storeLogo_url = res.data;
      this.storeLogo_img = URL.createObjectURL(file.raw);
    },
    /*
     *  门店营业执照图片上传
     */
    handleStoreLicenseUrlSuccess(res, file) {
      this.storeLicense_url = res.data;
      this.storeLicense_img = URL.createObjectURL(file.raw);
    },
    /*
     *   获取区id
     */
    handleArea(id) {
      this.$forceUpdate();
      this.region_id = id;
    },
    /*
     *  获取城市id
     */
    handleCity(id) {
      this.region_id = id;
      this.$refs["areaId"].resetField();

      const data = {
        pid: id,
      };
      getRegionList(data).then((res) => {
        if (res.code === 1) {
          this.areaList = res.data;
        }
      });
    },
    /*
      *   获取省id
     */
    handleProvie(id) {
      this.region_id = id;
      this.$refs["cityId"].resetField();
      this.$refs["areaId"].resetField();
      const data = {
        pid: id,
      };
      getRegionList(data).then((res) => {
        if (res.code === 1) {
          this.cityList = res.data;
        }
      });
    },
    /*
     *  省市区下拉数据
     */
    getProvinceList() {
      const data = {
        pid: 1,
      };
      getRegionList(data).then((res) => {
        if (res.code === 1) {
          this.provinceList = res.data;
        }
      });
    },
    getShopDetail() {
      shopDetails({ hierarchy_id: this.userInfo.hierarchy_id }).then((res) => {
        if (res.code === 1) {
          this.shopForm = res.data;
          this.storeLogo_img = res.data.logo;
          this.storeLogo_url = res.data.logo;
          this.storeLicense_img = res.data.license_url;
          this.storeLicense_url = res.data.license_url;
          this.region_id = res.data.region_id;

          if (res.data.region.length !== 0) {
            this.shopForm.provinceId = res.data.region.find(
              (item) => item.deep === 1,
            )?.id;
            this.shopForm.cityId = res.data.region.find(
              (item) => item.deep === 2,
            )?.id;
            this.shopForm.areaId = res.data.region.find(
              (item) => item.deep === 3,
            )?.id;
            this.getCityList(
              res.data.region.find((item) => item.deep === 1)?.id,
            );
            this.getAreaList(
              res.data.region.find((item) => item.deep === 2)?.id,
              () => {
                this.show = true;
              },
            );
          }
        }
      });
    },
    /*
     *  获取城市下拉数据
     */
    getCityList(id) {
      const data = {
        pid: id,
      };
      getRegionList(data).then((res) => {
        if (res.code === 1) {
          this.cityList = res.data;
        }
      });
    },
    /*
     *  获取地区下拉数据
     */
    getAreaList(id, callback) {
      const data = {
        pid: id,
      };
      getRegionList(data).then((res) => {
        if (res.code === 1) {
          this.areaList = res.data;
          if (callback && typeof callback === 'function') {
            callback();
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.box {
  background: white;
  padding: 20px;
}
/deep/ .title {
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 18px;

  .span {
    margin: auto 10px auto 0;
    width: 4px;
    height: 16px;
    background: #5493f5;
    border-radius: 2px;
  }
}
/deep/ .line {
  display: inline-block;
  vertical-align: middle;
  margin: 0 30px;
  width: 1px;
  height: 40px;
  background: #e9e9e9;
}

/deep/ .avatar-uploader .el-upload {
  width: 100px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
/deep/ .avatarOther {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
