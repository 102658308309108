<template>
  <div v-if="userInfo.hierarchy_type_id == 30">
    <!-- <div class="content">
      <div class="newly">
        <el-page-header @back="goBack" content="查看商家信息"></el-page-header>
      </div>
    </div> -->
    <div class="newForm" style="padding-bottom: 50px; margin-top: 0;">
      <div class="title">
        <div class="span"></div>
        公司信息
      </div>
      <div class="cutLine"></div>
      <div class="form">
        <el-row :gutter="20">
          <el-col :span="8"
            ><div class="rowText">
              <span style="color: #909399">所选代理商：</span>
              {{ info.connection_agent || "--" }}
            </div></el-col
          >
          <el-col :span="8"
            ><div class="rowText">
              <span style="color: #909399">公司性质：</span
              >{{ info.company_type_id_text || "--" }}
            </div></el-col
          >
          <el-col :span="8"
            ><div class="rowText">
              <span style="color: #909399">所属区域：</span>
              {{ info.region_name || "--" }}
            </div></el-col
          >
          <el-col :span="8"
            ><div class="rowText">
              <span style="color: #909399">法人姓名：</span>
              {{ info.legal_person_name || "--" }}
            </div></el-col
          >
          <el-col :span="8"
            ><div class="rowText">
              <span style="color: #909399">公司名称：</span>
              {{ info.name || "--" }}
            </div></el-col
          >
          <el-col :span="8"
            ><div class="rowText">
              <span style="color: #909399">详细地址：</span>
              {{ info.addr || "--" }}
            </div></el-col
          >
          <el-col :span="8"
            ><div class="rowText">
              <span style="color: #909399">固定电话：</span>
              {{ info.tel || "--" }}
            </div></el-col
          >
          <el-col :span="8"
            ><div class="rowText">
              <span style="color: #909399">手机号码：</span>
              {{ info.phone_num || "--" }}
            </div></el-col
          >
          <el-col :span="8"
            ><div class="rowText">
              <span style="color: #909399">公司简称：</span
              >{{ info.short_name || "--" }}
            </div></el-col
          >
          <el-col :span="8"
            ><div class="rowText">
              <span style="color: #909399">门店规模：</span
              >{{ info.store_scale || "--" }}
            </div></el-col
          >
          <el-col :span="8"
            ><div class="rowText">
              <span style="color: #909399">备注信息：</span
              >{{ info.remark || "--" }}
            </div></el-col
          >
          <!-- <el-col :span="8"
            ><div class="rowText" style="display: flex;">
              <span style="color: #909399; margin-right: 5px;">身份证正反面：</span>
              <div style="display: flex">
                <el-image
                  fit="contain"
                  style="width: 100px; height: 100px; margin-right: 15px"
                  :src="srcList[0] || no_img"
                ></el-image>
                <el-image
                  fit="contain"
                  style="width: 100px; height: 100px"
                  :src="srcList[1] || no_img"
                ></el-image>
              </div>
            </div></el-col
          > -->
          <el-col :span="8"
            ><div class="rowText">
              <span style="color: #909399; margin-right: 5px;">公司LOGO：</span
              ><el-image
                fit="contain"
                style="width: 100px; height: 100px"
                :src="info.logo || no_img"
              ></el-image></div
          ></el-col>
        </el-row>
      </div>
      <div class="title">
        <div class="span"></div>
        管理员账号
      </div>
      <div class="cutLine"></div>

      <div class="form">
        <el-row :gutter="20">
          <el-col :span="8"
            ><div class="rowText">
              <span style="color: #909399">管理员姓名：</span>
              {{ info.admin_name || "--" }}
            </div></el-col
          >
          <el-col :span="8"
            ><div class="rowText">
              <span style="color: #909399">管理员手机：</span>
              {{ info.admin_account || "--" }}
            </div></el-col
          >
        </el-row>
      </div>

      <template v-if="shopListTotal>1">
         <div class="title">
          <div class="span"></div>
            门店列表
        </div>

        <el-table :data="shopList" stripe border>

            <el-table-column
              type="index"
              label="序号"
              align="center"
              width="80"
            ></el-table-column>
          
            <el-table-column
              align="center"
              prop="name"
              label="门店名称">
            </el-table-column>

            <el-table-column
              align="center"
              prop="name"
              label="门店Logo">
              <template slot-scope="scope">
                  <template v-if="scope.row.logo">
                      <img style="width:40px;height:40px;" :src="scope.row.logo" alt="">
                  </template>
              </template>
            </el-table-column>

             <el-table-column
              align="center"
              prop="legal_person_name"
              label="店长">
            </el-table-column>

            <el-table-column
              align="center"
              prop="tel"
              label="店长手机号">
            </el-table-column>

             <el-table-column
              align="center"
              prop="region_id"
              label="所属区域">
            </el-table-column>

             <el-table-column
              align="center"
              prop="addr"
              label="详细地址">
            </el-table-column>

             <el-table-column
              align="center"
              prop="remark"
              label="备注">
            </el-table-column>

             <el-table-column
              align="center"
              prop="end_time"
              label="到期时间">
            </el-table-column>

        </el-table>

      </template>
      
      <template v-if="false">
        <div class="title">
          <div class="span"></div>
          门店管理
        </div>
        <div class="cutLine"></div>
        <el-table :data="storeList" stripe>
          <el-table-column
            align="center"
            prop="name"
            label="门店名称"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="admin_name"
            label="店长姓名"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="admin_account"
            label="店长手机号"
          ></el-table-column>
          <!-- <el-table-column
            align="center"
            fixed="right"
            prop="operation"
            label="操作"
          >
            <template slot-scope="scope">
              <div class="options">
                <el-select
                  v-model="scope.row.select"
                  placeholder="请选择"
                  @change="handleChange($event, scope.row)"
                >
                  <el-option label="编辑" :value="1"></el-option>
                  <el-option
                    label="续费"
                    :value="2"
                    v-if="scope.row.audit_text === '审核通过'"
                  ></el-option>
                  <el-option
                    label="审核"
                    :value="3"
                    v-if="scope.row.audit_text === '待审核'"
                  ></el-option>
                </el-select>
              </div>
            </template>
          </el-table-column> -->
        </el-table>
      </template>
      </div>
 
  </div>
  <div v-else>
    <Store />
  </div>
</template>

<script>
import { merchantDetail, shopList ,getStoreList } from "@/api/businessManage/business";
import no_img from "../../../assets/images/no_img.png";
import storage from 'good-storage';
import Store from './Store.vue';

export default {
  data() {
    return {
      userInfo: {},
      info: {},
      id: 0,
      companyType: {
        1: "国有企业",
        2: "私营企业",
        3: "港资企业",
        4: "外企",
      },
      addressText: "",
      url: "",
      srcList: [],
      storeList: [],
      no_img: no_img,
      shopList:[],
      shopListTotal:0,
    };
  },
  components: {
    Store,
  },
  mounted() {
    this.userInfo = storage.get('userInfo');
    if (this.userInfo.hierarchy_type_id == 30) {
      this.fetchDetail();
    }
    this.getStoreList()
    // this.fetchStore();
  },
  methods: {

    getStoreList(){
      getStoreList({}).then(res=>{
        if(res.code==1){
          console.log(res)
          this.shopList=res.data.list
          this.shopListTotal=res.data.total
        }
      })
    },

    fetchDetail() {
      const data = {
        hierarchy_id: this.userInfo.hierarchy_id,
      };
      merchantDetail(data).then((res) => {
        if (res.code === 1) {
          this.info = res.data;
          this.srcList = res.data.legal_person_img_url.split(",");

          if (res.data.region.length !== 0) {
            res.data.region.forEach((item) => {
              this.addressText += item.name;
            });
          }
        }
      });
    },
    /*
     *  获取商家下的门店信息
     */
    fetchStore() {
      const data = {
        hierarchy_pid: this.userInfo.hierarchy_id,
      };
      shopList(data).then((res) => {
        if (res.code === 1) {
          this.storeList = res.data.list;
        }
      });
    },
    /*
     *  返回
     */
    goBack() {
      this.$emit("recapture");
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .form {
  width: 90%;
  margin-top: 15px;
  padding-left: 30px;
}
/deep/ .title {
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  height: 60px;
  line-height: 60px;
  font-size: 18px;

  .span {
    margin: auto 10px auto 0;
    width: 4px;
    height: 16px;
    background: #5493f5;
    border-radius: 2px;
  }
}
/deep/ .cutLine {
  width: 100%;
  height: 1px;
  background: #f5f6fa;
}

/deep/ .newly {
  padding: 20px;
}

/deep/ .rowText {
  line-height: 50px;
}
</style>